import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import _ from 'lodash'
import IKUtils from 'innerken-js-utils'
import { showSuccessMessage } from '@/common/utils'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    form: false,
  },

  ratio: {
    displayName: 'ratio',
    formConfig: {
      required: true,
      overwriteRule: false,
    },
    form: false,
  },

  // minRatio: {
  //   displayName: 'minRatio',
  //   formConfig: {
  //     overwriteRule: false,
  //     rule: [
  //       // v => /^(-)?[0-9]+\.{0,1}[0-9]{0,2}$/.test(v) || 'please enter the correct min Ratio',
  //       v => (!isNaN(parseFloat(v)) && parseFloat(v) > 0) || 'The min Ratio should be greater than 0',
  //     ],
  //     required: false,
  //   },
  // },
  //
  // maxRatio: {
  //   displayName: 'maxRatio',
  //   formConfig: {
  //     overwriteRule: true,
  //     rule: [
  //       // v => (!isNaN(parseFloat(v)) && parseFloat(v) >= 0) || 'The max Ratio could not be negative',
  //     ],
  //     required: false,
  //   },
  // },

  color: {
    type: IKDataEntity.Types.Color,
    formConfig: {
      required: true,
    },
  },

}

const config = {

  add: async function (item) {
    // console.log('add item', item)
    // const sortedArray = this.getSortedColorList()
    // console.log('sortedArray before', sortedArray)
    let sortedArray = []
    const tempList = (await hillo.get('DCache.php', {
      op: 'showDCacheColor',
    }))
      .content

    // let frontItem = tempList[0]
    // tempList.forEach(function (value, index, array) {
    //   if (!value.maxRatio) {
    //     backItem = value
    //   }
    //   value.maxRatio < item.Ratio
    //   frontItem = value
    // })

    console.log('tempList 1', tempList)

    if (tempList.length <= 0) {
      return []
    } else {
      console.log('sortedArray 2 before', tempList)
      sortedArray = tempList.sort(function (obj1, obj2) {
        const val1 = obj1.maxRatio
        const val2 = obj2.maxRatio
        // val2 is null, that means val2 is infinity
        if (!val2 || parseFloat(val1) < parseFloat(val2)) {
          return -1
        } else if (!val1 || parseFloat(val1) > parseFloat(val2)) {
          return 1
        } else {
          return 0
        }
      })
      console.log('sortedArray 3 after', sortedArray)
    }

    // if colorList is empty, we will get two item for top-Ratio and bottom-Ration
    if (_.findIndex(sortedArray, function (o) {
      return o.maxRatio === item.Ratio
    }) >= 0) {
      console.log('they are the same')
      return true
    } else {
      console.log('sorted -1 Array', _.slice(sortedArray, 0, sortedArray.length - 1))
      const colorIndex = _.findIndex(_.slice(sortedArray, 0, sortedArray.length - 1),
        function (o) {
          return o.maxRatio < item.Ratio
        })

      console.log('colorIndex', colorIndex)

      if (colorIndex <= -1) {
        item.minRatio = null
        item.maxRatio = item.Ratio

        sortedArray[0].minRatio = item.Ratio

        console.log('colorIndex item', item)
        hillo.postWithUploadFile('DCache.php?op=updateDCacheColor', sortedArray[0], {
          showLoading: true,
        })
      } else {
        item.minRatio = sortedArray[colorIndex].maxRatio
        item.maxRatio = item.Ratio

        sortedArray[colorIndex + 1].minRatio = item.Ratio

        hillo.postWithUploadFile('DCache.php?op=updateDCacheColor', sortedArray[colorIndex], {
          showLoading: true,
        })
        hillo.postWithUploadFile('DCache.php?op=updateDCacheColor', sortedArray[colorIndex + 1], {
          showLoading: true,
        })
      }

      hillo.postWithUploadFile('DCache.php?op=createDCacheColor', item, {
        showLoading: true,
      })
      return true
    }
  },

  edit: function (item) {
    if (item.Ratio === '+Infi') {
      item.maxRatio = null
    } else {
      item.maxRatio = item.Ratio
    }
    return hillo.postWithUploadFile('DCache.php?op=updateDCacheColor', item, {
      showLoading: true,
    })
  },

  remove: async function (id) {
    const _data = (await hillo.get('DCache.php', {
      op: 'showDCacheColor',
    }))
      .content

    const _item = _data.find(i => i.id === JSON.stringify(id))

    if (!_item.maxRatio) {
      const frontItem = _data.find(i => i.maxRatio === _item.minRatio)
      frontItem.maxRatio = null
      hillo.postWithUploadFile('DCache.php?op=updateDCacheColor', frontItem, {
        showLoading: true,
      })
    } else {
      const backItem = _data.find(i => i.minRatio === _item.maxRatio)
      backItem.minRatio = _item.minRatio
      hillo.postWithUploadFile('DCache.php?op=updateDCacheColor', backItem, {
        showLoading: true,
      })
    }
    hillo.post('DCache.php?op=deleteDCacheColor', { id: id })
    return true
  },

  load: async function (filter) {
    const _data = (await hillo.get('DCache.php', {
      op: 'showDCacheColor',
      ...filter,
    }))
      .content

    console.log('tempItem', _data)
    let tempItem = null

    if (_data.length <= 0) {
      hillo.postWithUploadFile('DCache.php?op=createDCacheColor', {
        minRatio: null,
        maxRatio: 1,
        Ratio: 1,
        color: '#E57373',
      }, { showLoading: true })

      hillo.postWithUploadFile('DCache.php?op=createDCacheColor', {
        minRatio: 1,
        maxRatio: null,
        Ratio: '+Infi',
        color: '#A5D6A7',
      }, { showLoading: true })
    } else if (_data.length === 1) {
      // if(tempItem[0].maxRatio && )
      tempItem = _data[0]

      if (!tempItem.minRatio) {
        hillo.postWithUploadFile('DCache.php?op=createDCacheColor', {
          minRatio: tempItem.maxRatio,
          maxRatio: null,
          Ratio: '+Infi',
          color: '#A5D6A7',
        }, { showLoading: true })
      } else if (!tempItem.maxRatio) {
        hillo.postWithUploadFile('DCache.php?op=createDCacheColor', {
          minRatio: null,
          maxRatio: tempItem.minRatio,
          Ratio: '-Infi',
          color: '#E57373',
        }, { showLoading: true })
      } else {
        hillo.postWithUploadFile('DCache.php?op=createDCacheColor', {
          minRatio: tempItem.maxRatio,
          maxRatio: null,
          Ratio: '+Infi',
          color: '#A5D6A7',
        }, { showLoading: true })

        hillo.postWithUploadFile('DCache.php?op=createDCacheColor', {
          minRatio: null,
          maxRatio: tempItem.minRatio,
          Ratio: '-Infi',
          color: '#E57373',
        }, { showLoading: true })
      }
    } else {
      tempItem = _data
      for (const i in tempItem) {
        if (!tempItem[i].maxRatio) {
          tempItem[i].Ratio = '+Infi'
        } else {
          tempItem[i].Ratio = tempItem[i].maxRatio
        }
      }
    }

    console.log('load tempItem', tempItem)
    return tempItem
  },

  getSortedColorList: async function () {
    const tempList = (await hillo.get('DCache.php', {
      op: 'showDCacheColor',
    }))
      .content

    if (tempList.length <= 0) {
      return []
    } else {
      const sortedArray = tempList.sort(function (obj1, obj2) {
        const val1 = obj1.maxRatio
        const val2 = obj2.maxRatio
        // val2 is null, that means val2 is infinity
        if (!!val2 && val1 < val2) {
          return -1
        } else if (!!val1 && val1 > val2) { // val1 is null, that means val1 is infinity
          return 1
        } else {
          return 0
        }
      })

      console.log('getSortedList', tempList)
      return sortedArray
    }
  },

  resetColorList: async function () {
    const tempList = (await hillo.get('DCache.php', {
      op: 'showDCacheColor',
    }))
      .content

    IKUtils.showLoading(true)

    for (const item of tempList) {
      hillo.post('DCache.php?op=deleteDCacheColor', { id: item.id })
    }

    hillo.postWithUploadFile('DCache.php?op=createDCacheColor', {
      minRatio: null,
      maxRatio: 1,
      Ratio: 1,
      color: '#E57373',
    }, {})

    hillo.postWithUploadFile('DCache.php?op=createDCacheColor', {
      minRatio: 1,
      maxRatio: null,
      Ratio: '+Infi',
      color: '#A5D6A7',
    }, {})

    showSuccessMessage('OK')
  },

}

export default IKDataEntity.ModelFactory(entity, config)
