<template>
  <div>
    <ik-data-table
      ref="thresholdColor"
      :entity-name="$t('threshold_color')"
      :icon="'mdi-heart'"
      :model="model"
    >
      <template #footer>
        <v-btn
          class="red ml-2"
          @click="reset"
        >
          {{ $t('reset_product_threshold') }}
        </v-btn>
      </template>
    </ik-data-table>
  </div>
</template>

<script>
  import { IkDataTable } from 'metaflow-js'
  import DThresholdColorSetting from '../../../model/restaurant/DThresholdColorSetting'

  export default {

    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: DThresholdColorSetting,
      })
    },
    methods: {
      async reset () {
        await this.model.resetColorList()
        this.$refs.thresholdColor.reload()
      },
    },
  }
</script>

<style scoped>

</style>
